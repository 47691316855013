<template>
  <section id="dashboard-ecommerce" v-if="$can('read','statuswise_report') || $can('read','report')">
    
    <div>
     
        <div class="text-center"><h3>Status Wise Tracker</h3></div>

        <b-card>
          <div>
            <b-row>
              <h5>Select Fields</h5>
              <b-col col>
                <b-form-group>
                  <b-form-checkbox-group
                    id="checkbox-group-1"
                    v-model="selected"
                    :options="options"
                    name="fields-1"
                    class="demo-inline-spacing"
                  />
                </b-form-group>  
              </b-col>
            </b-row>  
            <b-row>  
              <b-col col v-if="$can('read','manager_filter')">
                <b-form-group
                  label="Team Lead"
                  label-for="accountMgr"
                >
                  <v-select
                      v-model="mgrSelected"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      label="title"
                      :options="accountMgr"
                      :reduce="(option) => option.title"
                      ref="accMgr"
                    />
                </b-form-group>
              </b-col>
              <b-col col v-if="$can('read','recruiter_filter')">
                <b-form-group
                  label="Recruiter"
                  label-for="recruiter"
                >
                  <v-select
                      v-model="recSelected"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      label="title"
                      :options="recruiters"
                      :reduce="(option) => option.title"
                      ref="rec"
                    />
                </b-form-group>
              </b-col>
              <b-col col>
                <b-form-group
                  label="Location"
                  label-for="location"
                >
                  <v-select
                      v-model="locSelected"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      label="title"
                      :options="location"
                      :reduce="(option) => option.title"
                      ref="loc"
                    />
                </b-form-group>
              </b-col>
              <b-col col>
                <b-form-group
                  label="Client"
                  label-for="client"
                >
                  <v-select
                      v-model="clientSelected"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      label="title"
                      :options="clients"
                      :reduce="(option) => option.title"
                      ref="client"
                    />
                </b-form-group>
              </b-col>
              <b-col col>
                  <b-form-group
                    label="Source"
                    label-for="source"
                  >
                    <v-select
                        v-model="sourceSelected"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        label="title"
                        :options="sources"
                        :reduce="(option) => option.title"
                        ref="source"
                      />
                  </b-form-group>
                </b-col>
            </b-row>
            <b-row class="mt-1">
              <b-col md="4" xl="4" xs="4" class="mb-1">
                <b-form-group
                  label="Start Date"
                  label-for="start-date"
                >
                  <flat-pickr
                    v-model="startDate"
                    class="form-control"
                    :config="{ enableTime: false,dateFormat: 'Y-m-d'}"
                  />
                  <small v-if="!startDate" class="text-danger">Start Date is Mandatory</small>
                </b-form-group>
              </b-col>    
              <b-col md="4" xl="4" xs="4" class="mb-1">
                <b-form-group
                  label="End Date"
                  label-for="end-date"
                >
                  <flat-pickr
                    v-model="endDate"
                    class="form-control"
                    :config="{ enableTime: false,dateFormat: 'Y-m-d'}"
                  />
                  <small v-if="!endDate" class="text-danger">End Date is Mandatory</small>
                </b-form-group>
              </b-col>
              <b-col md="4" xl="4" xs="4" class="mb-1">
                <b-form-group
                  label=""
                  label-for="">
                  <b-button variant="outline-primary" value="submit" id="submit" type="submit" width="100%" @click="getReport" style="margin-top: 20px">Get Report</b-button>
                </b-form-group>
              </b-col>
            </b-row>
            
            <div v-if="loading">
                <div class="text-center">
                  <b-spinner variant="primary" label="Text Centered" />
                </div>
            </div>
            <div v-if="!loading && compiledData ">
              
              <download-excel :data="compiledData" v-if="isReadyToDownload">
                <button type="button" class="btn btn-success mb-1">Download Report</button>
              </download-excel>
            <b-table striped hover responsive :items="compiledData"></b-table>
            </div>
            
            <div v-if="!loading && !compiledData ">
              <span class="text-danger">{{ reportStatus }}</span>
            </div>   
          </div>
        </b-card>  
      </div>
  </section>
</template>

<script>
import { BRow, BCol, BCard, BButton, BFormGroup, BSpinner, BFormCheckbox, BFormCheckboxGroup, BTable } from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'
import vSelect from 'vue-select'
import moment from 'moment'
import UserService from '@/services/user-service'
import clientService from '@/services/client-service'
import reportService from '@/services/report-service'
import adminConfigService from "@/services/admin-config-service";

// import ExportExcelSheetVueJs from "export-excel-sheet-vue-js";


export default {
  components: {
    BRow,
    BCol,
    BCard,
    BButton,
    BFormGroup,
    BSpinner,
    flatPickr,
    BFormCheckbox,
    BFormCheckboxGroup,
    // ExportExcelSheetVueJs,
    BTable,
    vSelect
    
  },
  data() {
    return {
      startDate: '',
      endDate: '',
      tracker: '',
      compiledData: [],
      loading: false,
      downloadReport: '',
      recruiters: '',
      recSelected: '',
      accountMgr: '',
      mgrSelected: '',
      sourceSelected: '',
      clients: '',
      clientSelected: '',
      location: [{ title: 'Pune' }, { title: 'Delhi'}],
      sources: [
          { title: "Naukri" },
          { title: "Monster" },
          { title: "ATS" },
          { title: "LinkedIn" },
          { title: "Indeed" },
          { title: "Shine" },
          { title: "TimesJobs" },
          { title: "IIMJobs" },
          { title: "Glassdoor" },
          { title: "FreshersWorld" },
          { title: "Other" },
          { title: "Train N Hire" },
          { title: "Internal" },
          { title: "Referenced" },
        ],
      locSelected: '',
      filename: '',
      columns: [{ label: 'Manager', field: 'Manager'}, { label: 'Recruiter', field: 'Recruiter'}, { label: 'Client', field: 'Client'}, { label: 'Location', field: 'Location'}, { label: 'Total Candidates', field: 'TotalCandidates'}, 
      { label: 'Profile Shared', field: 'ProfileShared'}, { label: 'Review Reject', field: 'ReviewReject'}, { label: 'Initiated', field: 'Initiated'}, { label: 'Not Interested', field: 'NotInterested'}, { label: 'Not Relevant', field: 'NotRelevant'}, 
      { label: 'Not Answered', field: 'NotAnswered'}, { label: 'Not Reachable', field: 'NotReachable'}, { label: 'Wrong Number', field: 'WrongNumber'}, { label: 'Call Back', field: 'CallBack'}, { label: 'Screen Select', field: 'ScreenSelect'},
      { label: 'Screen Reject', field: 'ScreenReject'}, { label: 'Duplicate', field: 'Duplicate'}, { label: 'InProcess', field: 'InProcess'}, { label: 'On Hold', field: 'OnHold'}, { label: 'Shortlisted', field: 'Shortlisted'},
      { label: 'Rejected', field: 'Rejected'}, { label: 'Offered', field: 'Offered'}, { label: ' Not Offered', field: 'NotOffered'}, { label: 'Offer Drop', field: 'OfferDrop'}, { label: 'Joined', field: 'Joined'}],
      reportStatus: '',
      selected: ["recruiter","manager","location","client", "source"],
      options: [
        { text: 'Recruiter', value: 'recruiter' },
        { text: 'Team Lead', value: 'manager' },
        { text: 'Location', value: 'location' },
        { text: 'Client', value: 'client' },
        { text: 'Source', value: 'source' },
      ],
      sheetname: 'Status Wise Tracker',
      isReadyToDownload: false,
      source: ''
    }
  },
  created() {
    this.startDate = moment().locale("en").add(-1, 'days').format("YYYY-MM-DD")
    this.endDate = moment().locale("en").add(0, 'days').format("YYYY-MM-DD")

    UserService.getAllUsersDd().then( res => {
      if(res.status === "OK") {
        console.log("in if")
        this.recruiters = res.data.filter(item => item.role === "talent-advisor-sourcer" || item.role === "account-manager" || item.role === "talent-advisor");
        this.accountMgr = res.data.filter(item => item.role === "account-manager");
        //this.sourcer = res.data.filter(item => item.role === "talent-advisor-sourcer" || item.role === "account-manager");
        //console.log(this.recruiters)
        //console.log(this.accountMgr)
        //console.log(this.sourcer)
      }
      
    })

    clientService.getClients().then(res => {
      this.clients = res.data
      console.log(this.clients)
    })
    
    //this.getReport();
  },
  methods: {
    getReport() {
      // if(!this.$can('read','statuswise_report') && !this.$can('read','report')) {
      //   return
      // }
      this.compiledData = []
      this.tracker = []
      if (this.startDate === '' || this.endDate === '') {
        this.reportStatus = 'Please Select Dates'
      } else {
        this.loading = true
        console.log(this.startDate, this.endDate, moment().locale("en").format("YYYY-MM-DD"))
        //console.log('DATE CHECK', new Date(this.startDate) < new Date(moment().locale("en").format("YYYY-MM-DD")))
        this.rec = this.selected.includes('recruiter')
        this.mgr = this.selected.includes('manager')
        this.loc = this.selected.includes('location')
        this.client = this.selected.includes('client')
        this.source = this.selected.includes('source')
        console.log(this.rec, this.mgr, this.loc, this.client)
        this.recSelected = this.recSelected == null || this.recSelected == undefined ? '' : this.recSelected
        this.mgrSelected = this.mgrSelected == null || this.mgrSelected == undefined ? '' : this.mgrSelected
        this.locSelected = this.locSelected == null || this.locSelected == undefined ? '' : this.locSelected
        this.sourceSelected = this.sourceSelected == null || this.sourceSelected == undefined ? '' : this.sourceSelected
        this.clientSelected = this.clientSelected == null || this.clientSelected == undefined ? '' : this.clientSelected
        if (new Date(this.startDate) <= new Date(moment().locale("en").format("YYYY-MM-DD")) && (new Date(this.startDate) <= new Date(this.endDate))) {
          reportService.getStatusWiseTracker(this.startDate, this.endDate, this.rec, this.mgr, this.loc, this.client, this.source, this.recSelected, this.mgrSelected, this.clientSelected, this.locSelected, this.sourceSelected).then( res => {
            if (res.status === "OK") {
              this.reportStatus = ''
              this.compiledData = res.data
              this.isReadyToDownload = true
              console.log(res)
              //this.downloadReport = 'data:application/xlsx;base64,' + res.file
              this.filename = 'Status Wise Tracker - ' + this.startDate + '_' + this.endDate + '.xlsx'
              
              this.loading = false

              if(!this.compiledData) {
                this.reportStatus = 'No Data found for selected dates'
              }
            } else if ((res.status === 401 ) || (res.status === 403 )) {
              window.sessionStorage.setItem('lastPage', window.location.href.split('/#')[1])
              this.reportStatus = 'Session Expired... Redirecting to Login Page'
              localStorage.clear();
              this.$router.push({ name: "auth-login" });
            } else if ((res.status === 400 ) ) {
              this.reportStatus = 'Some Error Occurred, Please Try after sometime. If Same Issue occurs multiple times, Kindly contact IT admin.'
              this.loading = false
              this.compiledData = null
            }
            else if ((res.status === 500 ) ) {
              this.reportStatus = 'ERROR: ' + res.data.message + 'Kindly contact IT admin.'
              this.loading = false
              this.compiledData = null
            }
            
          })
        } else if (new Date(this.startDate) > new Date(this.endDate)) {
          this.loading = false
          this.reportStatus = 'Start Date cannot be greater than End Date!!!'
        }
          else {
          this.loading = false
          this.reportStatus = 'Start Date cannot be greater than today!!!'
        }
      }  
    },
    numDaysBetween(d1, d2) {
        var diff = Math.abs(d1.getTime() - d2.getTime());
        return diff / (1000 * 60 * 60 * 24);
    }  
  },
  mounted() {
    adminConfigService.getATSDropdownList().then(res => {
      if(res.status === 200) {
        this.sources = res.data.data.sourceList
        // this.qualifications = res.data.data.qualificationList
      }
    })
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
<style scoped>
table, td {
  border: 1px solid rgb(97, 97, 97);
  border-collapse: collapse;
}
.col-my10 {
  width: 10% !important;
}
.col-my14 {
  width: 14% !important;
}
.col-my16 {
  width: 16% !important;
}
.col-my6 {
  width: 6% !important;
}
.col-my84 {
  width: 84% !important;
}
.col-my17 {
  width: 16.6% !important;
}
.col-my20 {
  width: 19.1% !important;
}
.col-my7 {
  width: 7.1447% !important;
}
.col-my40 {
  width: 40% !important;
}
.text-v-h-center {
  display: flex;
  justify-content: center; /* align horizontal */
  align-items: center; /* align vertical */
}
</style>
